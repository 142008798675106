import React from "react"
// import classNames from 'classnames'

const Hero = ({contentHero}) => {
  return (
    <div className="section-hero">
      <div className="container">
        <h1 className="section-heading">{contentHero.title}</h1>
        <p className="section-text">{contentHero.content}</p>
      </div>
    </div>
  )
}

export default Hero